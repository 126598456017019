@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Prompt:wght@200&display=swap');

.SponsorSettingsComp{
    margin-left: 50px;
    margin-right: 50px;
    margin-top:  20px
}
.alert-fixed {
    position:fixed;
    bottom: 0px;
    left: 50%;
    width: 100%;
    z-index:9999;
    border-radius:10px
}

.font{
    font-family: 'Montserrat', sans-serif;
    font-family: 'Prompt', sans-serif;
}

.form-font{
    font-family: 'Montserrat', sans-serif;
    font-family: 'Prompt', sans-serif;
    font-weight: bold;
}
/*
 *  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *  Created by Kaveen Abeyrathne for StudPro 5.0 as a place for students to look
 *  into What they can learn,companies to understand what to expect. This website
 *  was developed as a first step to taking StudPro to a higher elevation. You are
 *  free to update,share & modify the work for IEEE purposes ONLY.
 *
 *  Content of the source code is not authorized to use for any commercial purpose.
 *
 *  SuiCxDe007 | https://github.com/SuiCxDe007 | kaveenmadawa@gmail.com
 *
 *  ~ Copyright © 2023 SuiCxDe
 *  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 */

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500&display=swap');

.font-header{
    font-family: 'Encode Sans Condensed', sans-serif;
    font-family: 'Ubuntu', sans-serif;
    font-weight: bold;
}

.font-content{
    font-family: 'Encode Sans Condensed', sans-serif;
    font-family: 'Ubuntu', sans-serif;
    font-weight: bold;
}


.date {
    font-family: 'Encode Sans Condensed', sans-serif;
    font-family: 'Ubuntu', sans-serif;
    font-weight: bolder;
    color: black;
}


.cards:hover{
    box-shadow: 0 18px 26px -6px #ffe6b5;
}

.chips{
    margin-top: 5px;
}

.cards:hover .chips {
    background-color: #ed6c02;
}

.youtube-watch button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    visibility: hidden;
}

.youtube-watch:hover button {
    visibility: visible;
}


.card-overlay::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.5);
    visibility: hidden;
    transition: visibility 0.1s;
}

.card-overlay:hover::before {
    visibility: visible;
}

@import url('https://fonts.googleapis.com/css2?family=Encode+Sans+Condensed:wght@100&family=Ubuntu:wght@300&display=swap');

body {

    font-family: 'Raleway', sans-serif;
}
a{
    text-decoration: none;
}


.animate-ieee
{
    text-transform: uppercase;
    background-image: linear-gradient(
            -225deg,
            #006fff 0%,
            #2548ff 29%,
            #0318a9 67%,
            #196897 100%
    );
    background-size: auto auto;
    background-clip: border-box;
    background-size: 200% auto;
    color: #fff;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textclip 2s linear infinite;
    display: inline-block;
    font-size: 190px;
}
.animate-stud
{

    background-image: linear-gradient(
            -225deg,
            #fd9900 0%,
            #e07a05 29%,
            #ff9800 67%,
            #ffbf76 100%
    );
    background-size: auto auto;
    background-clip: border-box;
    background-size: 200% auto;
    color: #fff;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textclip 2s linear infinite;
    display: inline-block;
    font-size: 190px;
}
.animate-pro
{

    background-image: linear-gradient(
            -225deg,
            #020202 0%,
            #575757 29%,
            #2d2d2d 67%,
            #232323 100%
    );
    background-size: auto auto;
    background-clip: border-box;
    background-size: 200% auto;
    color: #fff;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textclip 2s linear infinite;
    display: inline-block;
    font-size: 190px;
}
.animate-rest
{

    background-image: linear-gradient(
            -225deg,
            #0022ff 0%,
            #575757 29%,
            #2d2d2d 67%,
            #232323 100%
    );
    background-size: auto auto;
    background-clip: border-box;
    background-size: 200% auto;
    color: #fff;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textclip 2s linear infinite;
    display: inline-block;
    font-size: 190px;
}

.animate-login
{

    background-image: linear-gradient(
            -225deg,
            #cecece 0%,
            #939393 29%,
            #2d2d2d 67%,
            #232323 100%
    );
    background-size: auto auto;
    background-clip: border-box;
    background-size: 200% auto;
    color: #fff;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textclip 2s linear infinite;
    display: inline-block;
    font-size: 190px;
}

.animate-step
{

    background-image: linear-gradient(
            -225deg,
            #020202 0%,
            #575757 29%,
            #2d2d2d 67%,
            #232323 100%
    );
    background-size: auto auto;
    background-clip: border-box;
    background-size: 200% auto;
    color: #fff;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textclip 2s linear infinite;
    display: inline-block;
    font-size: 190px;
}

@keyframes textclip {
    to {
        background-position: 200% center;
    }
}

.header-title-div{
    background-color: rgba(255,255,255,0.5);
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 10px;
}

.header-title-div:hover {
    background-color: rgba(255,255,255,0.8);
}
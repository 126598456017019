@import url('https://fonts.googleapis.com/css2?family=Encode+Sans+Condensed:wght@100&family=Ubuntu:wght@300&display=swap');
.card-title-text{
    font-family: 'Montserrat', sans-serif;
    font-family: 'Prompt', sans-serif;
    font-size: 40px;
    text-transform: uppercase;
}

.font{
    font-family: 'Montserrat', sans-serif;
    font-family: 'Prompt', sans-serif;
}


.WhatisComp{
    margin-left: 50px;
    margin-right: 50px;
}

.ov-desc{
    font-family: 'Montserrat', sans-serif;
    font-family: 'Prompt', sans-serif;
    font-size: 20px;
}
.main-part{
    margin: 0 auto;
    padding: 0 10%;
    width: 100%;
    text-align: center;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}
@media screen and (max-width: 767px) {
    .main-part{
        grid-template-columns: 1fr 1fr;
    }
}
@media screen and (max-width: 576px) {
    .main-part{
        grid-template-columns: 1fr;
    }
}
.cpanel{
    display: inline-block;
    background-color:#34495E;
    color:#fff;
    margin-top: 50px;
}
.cpanel:hover{
    background-color: #0a3762;
    box-shadow: 0px 2px 20px 2px rgba(0, 4, 26, 0.4);
}
.icon-part i{
    font-size: 30px;
    padding:10px;
    border:1px solid #fff;
    border-radius:50%;
    margin-top:-25px;
    margin-bottom: 10px;
    background-color:#34495E;
}
.icon-part p{
    margin:0px;
    font-size: 20px;
    padding-bottom: 10px;
}
.card-content-part{
    background-color: #2F4254;
    padding: 5px 0px;
}
.card-content-part:hover{
    background-color: #2F4254;
    padding: 5px 0px;
}
.cpanel .card-content-part:hover{
    background-color: #5a5a5a;
    cursor: pointer;
}
.card-content-part a{
    color:#fff;
    text-decoration: none;
}
.cpanel-green .icon-part{
    background-color: #16A085;
}

.cpanel-green .icon-part:hover{
    background-color: #107c66;
}

.cpanel-green .icon-part i{
    background-color: #16A085;
}

.cpanel-orange{
    background-color: #F39C12;
}
.cpanel-orange:hover{
    background-color: #be7d14;
}
.cpanel-orange .icon-part i{
    background-color: #F39C12;
}

.cpanel-blue .icon-part{
    background-color: #2980B9;
}
.cpanel-blue .icon-part:hover{
    background-color: #216693;
}
.cpanel-blue .icon-part,.cpanel-blue .icon-part i{
    background-color: #2980B9;
}

.cpanel-red .icon-part{
    background-color:#E74C3C;
}
.cpanel-red .icon-part:hover{
    background-color: #933026;
}
.cpanel-red .icon-part,.cpanel-red .icon-part i{
    background-color:#E74C3C;
}

.cpanel-skyblue .icon-part,.cpanel-skyblue .icon-part i{
    background-color:#8E44AD;
}

.cpanel-skyblue .icon-part:hover{
    background-color: #5e2c73;
}

.cpanel-skyblue .icon-part{
    background-color:#8E44AD;
}
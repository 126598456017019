

@import url('https://fonts.googleapis.com/css2?family=Encode+Sans+Condensed:wght@100&family=Ubuntu:wght@300&display=swap');

.font{
    font-family: 'Montserrat', sans-serif;
    font-family: 'Prompt', sans-serif;
}

.sponsorship-component{
     margin-left: 50px;
     margin-right: 50px;
     margin-top: 20px;

 }
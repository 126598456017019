.input-border {
    border-color: #0080ff !important;
}

.inputLabel {
    color: #0080ff !important;
}

.helper-text {
    text-transform: initial;
    font-size: 1rem !important;
}